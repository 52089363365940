import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, Route } from 'react-router-dom';
import _ from 'lodash';

import type { EntitlementContextType } from '../../../context/entitlement.context';
import type { PlansDurationSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/PlansDurationSlice/PlansDurationSlice';
import type { ConfigContextType } from '../../../context/config.context';
import type { DisclaimerContextType } from '../../../context/disclaimer.context';
import type { MarketContextType } from '../../../context/market.context';
import type { QueryStringContextType } from '../../../context/querystring.context';
import type {
  OfferBundle,
  OfferBundleOfferPlan,
} from '../../../hooks/useProducts';

import { PlansDurationSlice } from '@ww-digital/web-palette-react/dist/components/Slice/PlansDurationSlice/PlansDurationSlice';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { AppUtility } from '../../Utility/AppUtility.ts';
import { EntitlementContext } from '../../../context/entitlement.context.ts';
import { ProductsUtility } from '../../Utility/ProductsUtility.ts';
import { PricingUtility } from '../../Utility/PricingUtility.ts';
import { MarketContext } from '../../../context/market.context.ts';
import { ConfigContext } from '../../../context/config.context.ts';
import { QueryStringContext } from '../../../context/querystring.context.ts';
import { DisclaimerContext } from '../../../context/disclaimer.context.ts';
import { usePostalCodeAndLocation } from '../../../hooks/usePostalCodeAndLocation.ts';
import { useProducts } from '../../../hooks/useProducts.ts';
import { useZipOwner } from '../../../hooks/useZipOwner.ts';
import { useHasLocations } from '../../../hooks/useHasLocations.ts';
import wwUtility from '../../../ww.utility.ts';

type PlansDurationPlansDataType = {
  subscriptionType: string;
  offerBundleId: string;
};

// Temporary props added for current XS test(s)
type PlansDurationSliceXSProps = {
  xsExperimentTextId?: string;
  sliceData?: PlansDurationSliceProps;
};

export type PlansDurationSliceExtendedProps = PlansDurationSliceProps &
  PlansDurationSliceXSProps & {
    plansData: PlansDurationPlansDataType[];
    defaultItemName: string;
    franchiseUrl: string;
    redirectOnFranchise?: boolean;
  };

interface PlansDurationSliceContainerProps {
  daCategory: string;
  slice: PlansDurationSliceExtendedProps;
}

interface ZipOwnerData {
  ownerId: string | null;
  loading: boolean;
}

interface HasLocationsData {
  hasLocations: boolean;
  loading: boolean;
}

const ZipOwner = (
  postalCode: string,
  zipOwner: React.MutableRefObject<ZipOwnerData>,
): void => {
  const { ownerId, loading } = useZipOwner(postalCode);

  zipOwner.current = {
    ownerId,
    loading,
  };
};

const HasLocations = (
  postalCode: string,
  hasLocationsData: React.MutableRefObject<HasLocationsData>,
): void => {
  const { hasLocations, loading } = useHasLocations(postalCode);

  hasLocationsData.current = {
    hasLocations,
    loading,
  };
};

export const PlansDurationSliceContainer = ({
  daCategory,
  slice,
}: PlansDurationSliceContainerProps): JSX.Element => {
  const { pathname } = useLocation();
  const queryArgs = useContext<QueryStringContextType>(QueryStringContext);
  const { config, translations } = useContext<ConfigContextType>(ConfigContext);
  const marketContext = useContext<MarketContextType>(MarketContext);
  const { entitlement } =
    useContext<EntitlementContextType>(EntitlementContext);
  const version = slice.version ? slice.version : 'modal';
  const { setDisclaimer } =
    useContext<DisclaimerContextType>(DisclaimerContext);
  const isCheckoutPage = ProductsUtility.isCheckoutPage(
    queryArgs,
    pathname,
    translations,
    marketContext.country,
    marketContext.language,
  );
  const dataLoaded = useRef(false);

  // OfferBundles are based on the slice's JSON contents or a single OfferBundle query-arg
  const offerBundleInputs = queryArgs.ob
    ? [{ offerBundleId: queryArgs.ob }]
    : slice.plansData.map((item: PlansDurationPlansDataType) => ({
        offerBundleId: item.offerBundleId || undefined,
        subscriptionType: item.offerBundleId
          ? undefined
          : item.subscriptionType,
      }));

  const switcherAction =
    version === 'switcher'
      ? 'buttonswitcher'
      : version === 'toggle'
        ? 'underlineswitcher'
        : '';

  // Initialize subscriptionType.
  // If defaultItemName is an offerBundleId, it needs to be initialized after data load.
  // On Checkout page, do not initialize at all.
  const stInit = isCheckoutPage
    ? ''
    : ProductsUtility.subscriptionType(queryArgs.st || '')
      ? ProductsUtility.subscriptionType(queryArgs.st || '')
      : ProductsUtility.subscriptionType(slice.defaultItemName) || '';

  const [changePlanItemName, setChangePlanItemName] = useState<string>(stInit);
  const [subscriptionType, setSubscriptionType] = useState<string>(stInit);
  const [offerPlanId, setOfferPlanId] = useState<string>('');
  const [zipEntered, setZipEntered] = useState<boolean>(false);
  const [zipLoading, setZipLoading] = useState<boolean>(false);

  // Clinical Page determined after Offer Bundles are loaded.
  const [isClinicalPage, setIsClinicalPage] = useState<boolean>(false);

  // @TODO also handle error return value
  const {
    loading: locationLoading,
    postalCode,
    updateUserPostalCode,
  } = usePostalCodeAndLocation();
  const zipOwnerData = useRef<ZipOwnerData>({ ownerId: null, loading: true });
  const hasLocationsData = useRef<HasLocationsData>({
    hasLocations: true,
    loading: true,
  });

  // Resolve the OwnerId.
  // OwnerId on non-US is always NACO 37.
  // OwnerId for a Core plan is always NACO 37.
  // OwnerId on US is based on zip location (via hook), except for the following case.
  // OwnerId on US is the "own" query-arg value IF it is a Checkout page with "own" + either "st" or "ob" query-arg.
  // Otherwise retrieve OwnerId from hook.
  const hasOwnerOverride =
    isCheckoutPage &&
    marketContext.country === 'us' &&
    !!queryArgs.own &&
    (!!queryArgs.st || !!queryArgs.ob);
  let ownerId = hasOwnerOverride ? queryArgs.own || '37' : '37';
  let zipOwnerLoading = false;
  if (!hasOwnerOverride && marketContext.country === 'us') {
    ZipOwner(postalCode, zipOwnerData);
    ownerId = zipOwnerData.current.ownerId || '';
    zipOwnerLoading = zipOwnerData.current.loading;
  }

  // Resolve the HasLocations.
  let hasLocations = true;
  let hasLocationsLoading = false;
  if (!isCheckoutPage && marketContext.country === 'us') {
    HasLocations(postalCode, hasLocationsData);
    hasLocations = hasLocationsData.current.hasLocations;
    hasLocationsLoading = zipOwnerData.current.loading;
  }

  const stg = !!queryArgs.sid && !!queryArgs.vid && queryArgs.ppm === 'stg';
  const { offerBundles: offerBundlesData, loading: offerBundlesLoading } =
    useProducts(offerBundleInputs, ownerId, stg);

  // If this is Checkout Page based on st and own, discard any OfferBundles not matching st
  const offerBundlesMemo = useMemo(
    () =>
      (
        offerBundlesData: OfferBundle[],
        queryArgs: QueryStringContextType,
        isCheckoutPage: boolean,
      ) =>
        !!offerBundlesData && isCheckoutPage && !queryArgs.ob && !!queryArgs.st
          ? offerBundlesData.filter((ob: OfferBundle) =>
              ProductsUtility.stEquals(
                ob.subscriptionType,
                queryArgs.st as string,
              ),
            )
          : offerBundlesData || [],
    [],
  );
  const offerBundles = offerBundlesMemo(
    offerBundlesData,
    queryArgs,
    isCheckoutPage,
  );

  // Return checkout URL.
  const getCheckoutUrl = (
    op: string | null,
    own: string | null,
    ob: string | null,
  ) => {
    const env = AppUtility.getEnv();
    const basePath = marketContext.marketBasePath;
    const returnPath =
      pathname && basePath
        ? pathname.replace(new RegExp(`^${basePath.replace('/', '\\/')}`), '')
        : '';
    const st =
      !!queryArgs.st && Object.keys(queryArgs).includes('own') && !queryArgs.ob
        ? queryArgs.st
        : '';
    const returnOB = !!queryArgs.ob;
    // Invite a friend (iaf) query params
    const { iafToken, iafReferrerOC, iafCC } = queryArgs || {};

    const ma =
      isClinicalPage && ProductsUtility.stEquals(subscriptionType, 'CORE')
        ? 'false'
        : '';

    const aid =
      isClinicalPage && ProductsUtility.stEquals(subscriptionType, 'CLINICAL')
        ? ProductsUtility.getAssessmentId()
        : '';

    return ProductsUtility.getCheckoutUrl(
      env,
      marketContext,
      op || '',
      own || '',
      ob || '',
      returnPath,
      st,
      returnOB,
      iafToken,
      iafReferrerOC,
      iafCC,
      aid,
      ma,
    );
  };

  const supportsLocation = () => {
    return marketContext.country === 'us';
  };

  const getLocationAware = () => {
    if (!subscriptionType) {
      return false;
    }
    const isLocationAware =
      supportsLocation() &&
      ProductsUtility.stEquals(subscriptionType, 'premium');
    return isLocationAware;
  };

  const getFranchiseUrl = () => {
    return slice.redirectOnFranchise
      ? slice?.franchiseUrl
      : slice?.franchiseUrl && !zipEntered
        ? slice.franchiseUrl
        : '';
  };

  const getRedirectUrl = () => {
    if (
      wwUtility.isBrowser() &&
      window &&
      supportsLocation() &&
      !isCheckoutPage &&
      ownerId !== '37'
    ) {
      return getFranchiseUrl();
    }
    return '';
  };

  const getSlicePricing = () => {
    const noZip = !postalCode;
    const slicePricing = {
      input: {
        buttonText: translations.PRICING_INPUT_BUTTON_TEXT,
        onSubmit: async (newPostalCode: string) => {
          await updateUserPostalCode({
            variables: { postalCode: newPostalCode },
          });
          setZipEntered(true);
        },
        placeholder: translations.PRICING_INPUT_PLACEHOLDER,
        regex: PricingUtility.zipcodeRegExp(marketContext.country),
        regexErrorMessage: translations.PRICING_INPUT_ERROR,
        loading: zipEntered && zipLoading,
      },
      zipText:
        getLocationAware() && !noZip && !isCheckoutPage
          ? // && !plansDurationSlice.showPricingForm @TODO
            translations.PRICING_ZIP_TEXT
          : '',
      zipOrLocation: noZip ? '' : postalCode,
    };

    return slicePricing;
  };

  // Return da planName, which is based on the st.
  const daPlanName = (planName: string) => {
    const stNames: { [key: string]: string } = {
      CORE: 'digital',
      PREMIUM: 'workshops',
      PERSONAL_COACHING: 'coaching',
      CLINICAL: 'clinical',
    };
    const stName: string | undefined = stNames[planName.toUpperCase()];
    return stName ? stName : planName;
  };

  const daDuration = (duration: string) => {
    return duration ? duration.replace(/\W/g, '').toLowerCase() : duration;
  };

  const getDACategory = (
    entitlement: EntitlementContextType['entitlement'],
    suffix: string,
  ) => {
    return AnalyticsUtility.formatCategory(entitlement, `dfpricing_${suffix}`);
  };

  const getDALabel = (plan: string, duration = '') => {
    const label = duration ? `${plan}_${daDuration(duration)}` : plan;
    return AnalyticsUtility.formatLabel(label);
  };

  // Return basic slice JSON.
  const getBasicSlice = (): PlansDurationSliceExtendedProps => {
    const basicSlice: PlansDurationSliceExtendedProps = {
      version: config?.pricing?.enableChangeMembershipSwitch
        ? 'toggleV2'
        : 'modal',
      changeDuration: {},
      changePlan: {},
      changePlanCTA: {
        text: translations.PRICING_MODAL_OPEN_LINK,
        attributes: {},
      },
      description: '',
      headline: '',
      pricing: undefined,
      pricingError: {
        red: false,
        text: '',
      },
      showPricingForm: false,
      textPrice: {
        text: '',
        price: '',
      },
      planCancel: {
        text: translations.PRICING_MODAL_CANCEL,
        attributes: {},
      },
      planSubmit: {
        text: translations.PRICING_MODAL_OPEN_LINK,
        attributes: {},
      },
      promoCodeInput: null,
      showPromoField: false,
      plansData: [],
      defaultItemName: '',
      franchiseUrl: '',
      mobileCarousel: slice?.mobileCarousel === false ? false : true,
    };
    return basicSlice;
  };

  useEffect(() => {
    if (offerBundlesLoading) {
      return;
    }

    let nextSubscriptionType = subscriptionType;

    if (offerBundlesData && offerBundlesData.length) {
      // The first time OfferBundle is loaded.
      if (!dataLoaded.current) {
        // Get the OfferBundle associated Checkout Page query-args, if any
        let checkoutBasedOfferBundle = null;

        // This is a Clinical Page if there is a Clinical and a Core OfferBundle.
        const checkIfClinicalPage =
          offerBundlesData.some((ob: OfferBundle) =>
            ProductsUtility.stEquals(ob.subscriptionType, 'clinical'),
          ) &&
          offerBundlesData.some((ob: OfferBundle) =>
            ProductsUtility.stEquals(ob.subscriptionType, 'core'),
          );
        setIsClinicalPage(checkIfClinicalPage);

        if (isCheckoutPage) {
          if (queryArgs.op) {
            checkoutBasedOfferBundle =
              offerBundlesData.find(
                (ob: OfferBundle) => ob.offerBundleId === queryArgs.op,
              ) || null;
          } else {
            checkoutBasedOfferBundle =
              offerBundlesData.find((ob: OfferBundle) =>
                ProductsUtility.stEquals(
                  ob.subscriptionType,
                  queryArgs.st || '',
                ),
              ) || null;
          }
        }

        // Get the OfferBundle associated with an op query-arg, if any
        const opBasedOfferBundle =
          (queryArgs.op &&
            offerBundlesData.find((ob: OfferBundle) =>
              ob.offerPlans?.find(
                (offerPlan) => offerPlan?.offerPlanData?.id === queryArgs.op,
              ),
            )) ||
          null;

        // Initialize the subscriptionType if necessary
        if (
          !nextSubscriptionType ||
          !ProductsUtility.getOfferBundle(
            offerBundlesData,
            '',
            nextSubscriptionType,
          ) ||
          isCheckoutPage ||
          queryArgs.op
        ) {
          // If this is a CheckoutPage, the OB/ST-own queryArg determines the OfferBundle
          // Else If there is an OfferPlan op queryArg, that takes highest precedence
          // Else If no OP-based default, get the one based on Slice JSON default,
          // and if there is no default, choose the first one in the slice
          const defaultOfferBundle = checkoutBasedOfferBundle
            ? checkoutBasedOfferBundle
            : opBasedOfferBundle
              ? opBasedOfferBundle
              : offerBundlesData.find(
                  (ob: OfferBundle) =>
                    ob.offerBundleId === slice.defaultItemName,
                ) || (offerBundlesData.length ? offerBundlesData[0] : null);
          if (defaultOfferBundle) {
            nextSubscriptionType = defaultOfferBundle.subscriptionType;

            if (checkIfClinicalPage && ['false', '0'].includes(queryArgs.ma)) {
              nextSubscriptionType = 'core';
            }

            setChangePlanItemName(nextSubscriptionType);
            setSubscriptionType(nextSubscriptionType);
          }
        }

        const curOfferBundle = offerBundlesData.find((ob: OfferBundle) =>
          ProductsUtility.stEquals(ob.subscriptionType, nextSubscriptionType),
        );

        // Set the default OfferPlan to either:
        // the one identified by the op queryArg (on initial display), or else
        // the one identified by the current OfferBundle's preSelected id,
        // the first OfferPlan in the bundle
        if (curOfferBundle?.offerPlans?.length) {
          const preSelectedOfferPlan = curOfferBundle?.preSelectedOfferPlanId
            ? curOfferBundle.offerPlans.find(
                (op: OfferBundleOfferPlan) =>
                  op.offerPlanId === curOfferBundle?.preSelectedOfferPlanId,
              ) || null
            : null;
          const defaultOfferPlanId =
            !dataLoaded.current && opBasedOfferBundle
              ? `${queryArgs.op}`
              : preSelectedOfferPlan
                ? preSelectedOfferPlan.offerPlanId
                : curOfferBundle.offerPlans[0].offerPlanId;
          setOfferPlanId(defaultOfferPlanId);
        }

        dataLoaded.current = true;
      }
    }
  }, [
    subscriptionType,
    offerBundlesLoading,
    offerBundlesData,
    slice.defaultItemName,
    isCheckoutPage,
    queryArgs.op,
    queryArgs.st,
    queryArgs.ma,
  ]);

  useEffect(() => {
    setZipLoading(
      locationLoading ||
        zipOwnerLoading ||
        hasLocationsLoading ||
        offerBundlesLoading,
    );
  }, [
    locationLoading,
    zipOwnerLoading,
    hasLocationsLoading,
    offerBundlesLoading,
  ]);

  useEffect(() => {
    const offerBundle = ProductsUtility.getOfferBundle(
      offerBundles,
      '',
      subscriptionType,
    );

    if (offerBundle) {
      setDisclaimer(ProductsUtility.getDisclaimer(offerBundle, marketContext));
    } else {
      setDisclaimer('');
    }
  }, [subscriptionType, offerBundles, marketContext, setDisclaimer]);

  // submit new plan from modal
  const onPlanSubmit = () => {
    setSubscriptionType(changePlanItemName);
  };

  // change plan name within modal
  const onChangePlanChange = (itemName: string) => {
    // Keep track of the latest plan selected in modal so
    // that we can send an analytics event on modal submit.
    setChangePlanItemName(itemName);
  };

  // change and submit plan for switcher or toggle version
  const onSwitchPlan = (name: string) => {
    // Bypass the ChangePlan switcher for Clinical Plan toggle.
    if (isClinicalPage) {
      return;
    }

    setChangePlanItemName(name);
    setSubscriptionType(name);
  };

  // change the offer within the offer bundle
  const onDurationChange = (id: string) => {
    setOfferPlanId(id);

    const offerBundle = ProductsUtility.getOfferBundle(
      offerBundles,
      '',
      subscriptionType,
    );
    const offerPlan = offerBundle
      ? offerBundle.offerPlans?.find(
          (op: OfferBundleOfferPlan) => op.offerPlanId === id,
        )
      : null;
    const offerPlanData = offerPlan?.offerPlanData || null;

    if (offerPlanData) {
      ProductsUtility.storeOfferPlanData(
        offerPlanData,
        stg,
        marketContext.country,
        marketContext.language,
      );
    }
  };

  // Toggle the Medication Access setting
  const onMedicationAccessChange = () => {
    const button = document.querySelector('#next_step_cta');
    if (!button) return;

    const scrollPosition = window.scrollY;
    const buttonPosition = button.getBoundingClientRect().top + scrollPosition;

    requestAnimationFrame(() => {
      const newButton = document.querySelector('#next_step_cta');
      if (!newButton) return;

      // Calculate the new button's position relative to the document
      const newButtonPosition =
        newButton.getBoundingClientRect().top + window.scrollY;

      // Scroll to the new position to make up for the change in height
      window.scrollTo({
        top: scrollPosition + (newButtonPosition - buttonPosition),
      });
    });

    const newSubscriptionType = ProductsUtility.stEquals(
      subscriptionType,
      'CLINICAL',
    )
      ? 'CORE'
      : 'CLINICAL';
    setChangePlanItemName(newSubscriptionType);
    setSubscriptionType(newSubscriptionType);

    const daAction =
      newSubscriptionType === 'CLINICAL' ? 'clinical_add' : 'clinical_remove';
    AnalyticsUtility.fireEvent(daCategory, daAction, 'medication_access');
  };

  const redirectUrl = getRedirectUrl();
  if (redirectUrl) {
    return (
      <Route
        component={() => {
          window.location = redirectUrl as any;
          return null;
        }}
      />
    );
  }

  let sliceView: PlansDurationSliceExtendedProps | null = null;
  let plansDurationSlice = null;

  // Show loading display only on initial page load.
  if ((offerBundlesLoading || zipLoading) && !zipEntered) {
    plansDurationSlice = <PlansDurationSlice {...getBasicSlice()} />;
  } else {
    // PlansDurationSlice JSON data is ONLY used to provide the OfferBundleIDs.
    // All other slice data in the JSON is ignored.
    // The displayed sliceView is based on a standard template.
    sliceView = getBasicSlice();
    sliceView.pricing = {}; // Removes the loading skeleton

    const currOfferPlan = offerBundles.find((bundle: OfferBundle) =>
      bundle.offerPlans.some((plan) => plan.offerPlanId === offerPlanId),
    );

    const changePlanLabel = daPlanName(changePlanItemName);

    const offerBundle = ProductsUtility.getOfferBundle(
      offerBundles,
      '',
      subscriptionType,
    );

    const sequenceRedirectUrl = isClinicalPage
      ? ProductsUtility.getSequenceRedirectUrl(offerBundle, translations)
      : '';
    const suppressRedirect =
      AppUtility.getEnv() !== 'prod' && queryArgs.noredirect === 'true';
    if (!!sequenceRedirectUrl && !suppressRedirect) {
      return (
        <Route
          component={() => {
            window.location = sequenceRedirectUrl as any;
            return null;
          }}
        />
      );
    }

    const currDurationData = currOfferPlan?.offerPlans?.find(
      (op: OfferBundleOfferPlan) => op.offerPlanId === offerPlanId,
    )?.offerPlanData;
    const currDurationNormalizedOffer =
      currDurationData && offerBundle
        ? ProductsUtility.getNormalizedOffer(
            currDurationData,
            marketContext.country,
            marketContext.language,
            offerBundle?.offerBundleId,
            ownerId,
          )
        : null;
    const currDuration = currDurationNormalizedOffer
      ? currDurationNormalizedOffer.title
      : '';

    sliceView.headline = ProductsUtility.getHeadline(offerBundle);
    sliceView.subheadline = ProductsUtility.getSubheadline(offerBundle);
    sliceView.description = ProductsUtility.getDescription(offerBundle);
    sliceView.descriptionFeaturesTitle =
      ProductsUtility.getFeaturesTitle(offerBundle);
    sliceView.descriptionFeatures =
      ProductsUtility.getDescriptionFeatures(offerBundle);
    sliceView.descriptionFeaturesTitle2 = ProductsUtility.getFeaturesTitle(
      offerBundle,
      2,
    );
    sliceView.descriptionFeatures2 = ProductsUtility.getDescriptionFeatures(
      offerBundle,
      2,
    );
    sliceView.changePlan = ProductsUtility.getChangePlan(
      offerBundles,
      {
        modalTitle: translations.PRICING_MODAL_TITLE,
      },
      !!config?.pricing?.enableChangeMembershipSwitch,
    );
    sliceView.changeDuration = ProductsUtility.getChangeDuration(
      offerBundle,
      translations,
      marketContext,
      entitlement,
      offerPlanId || '',
      isClinicalPage,
    );
    if (sliceView.changeDuration && offerPlanId) {
      sliceView.changeDuration.cta = {
        url: getCheckoutUrl(
          offerPlanId,
          ownerId,
          offerBundle?.offerBundleId || '',
        ),
        text: translations.PRICING_DURATION_NEXT,
        newWindow: false,
      };
      sliceView.changeDuration.cta.attributes = {
        id: 'next_step_cta',
      };

      if (isClinicalPage) {
        sliceView.changeDuration.cta.attributes['da-category'] = getDACategory(
          entitlement,
          'pricing',
        );
      } else {
        sliceView.changeDuration.cta.attributes['da-category'] = getDACategory(
          entitlement,
          changePlanLabel,
        );
      }
      sliceView.changeDuration.cta.attributes['da-action'] = 'continue';
      sliceView.changeDuration.cta.attributes['da-label'] = getDALabel(
        changePlanLabel,
        currDuration,
      );
    }

    sliceView.onDurationChange = onDurationChange;

    sliceView.onPlanSubmit = onPlanSubmit;
    if (sliceView.changePlan) {
      if (changePlanItemName) {
        sliceView.changePlan.defaultItemName =
          ProductsUtility.subscriptionType(changePlanItemName);
      }
      sliceView.changePlan.onChange =
        sliceView.version === 'modal' ? onChangePlanChange : onSwitchPlan;
    }

    // Change Plan button.
    if (sliceView.changePlanCTA?.text) {
      sliceView.changePlanCTA.attributes = {};
      sliceView.changePlanCTA.attributes['da-category'] = getDACategory(
        entitlement,
        changePlanLabel,
      );
      sliceView.changePlanCTA.attributes['da-action'] = 'change';
    }

    // ChangePlan modal cancel button.
    if (sliceView.planCancel?.text) {
      sliceView.planCancel.attributes = {};
      sliceView.planCancel.attributes['da-category'] = getDACategory(
        entitlement,
        'change',
      );
      sliceView.planCancel.attributes['da-action'] = 'cancel';
    }

    // ChangePlan modal submit button.
    if (sliceView.planSubmit?.text) {
      sliceView.planSubmit.attributes = {};
      sliceView.planSubmit.attributes['da-category'] = getDACategory(
        entitlement,
        'change',
      );
      sliceView.planSubmit.attributes['da-action'] = 'continue';
      sliceView.planSubmit.attributes['da-label'] = getDALabel(changePlanLabel);
    }

    // Tooltips.
    if (sliceView.changeDuration?.items) {
      sliceView.changeDuration.items.forEach((item) => {
        if (item?.tooltip?.content) {
          item.tooltip.attributes = {};
          item.tooltip.attributes['da-category'] = getDACategory(
            entitlement,
            changePlanLabel,
          );
          item.tooltip.attributes['da-action'] = 'tooltip';
          // The duration of the tooltip, not the currently selected duration
          item.tooltip.attributes['da-label'] = getDALabel(
            changePlanLabel,
            item.label,
          );
        }
      });
    }

    // For non-modal slices.
    if (sliceView.version !== 'modal') {
      if (sliceView.changePlan && sliceView.changePlan.items) {
        sliceView.changePlan.items.forEach((item) => {
          const attributes = {
            'da-category': getDACategory(entitlement, 'switch'),
            'da-action': switcherAction,
            'da-label': `daPlanName(item.name || '')`,
          };
          item.switcherAttributes = attributes;
        });
      }
    }

    sliceView.pricing = getSlicePricing();

    sliceView.showPricingForm =
      getLocationAware() && (zipLoading || !postalCode) && !isCheckoutPage;

    // Possible error text:
    // promoUnavailableLabel: when requested OB is not available, so fallback OB is used
    // locationUnavailableLabel: when premium OB has no locations;
    let errorText = '';
    if (offerBundle?.isFallback) {
      errorText = translations.PRICING_PROMO_UNAVAILABLE_LABEL;
    }
    if (
      !hasLocations &&
      ProductsUtility.stEquals(subscriptionType, 'premium')
    ) {
      errorText = translations.PRICING_LOCATION_UNAVAILABLE_LABEL;
    }

    sliceView.pricingError = {
      text: errorText,
      red: false,
    };

    if (
      offerBundle?.contents &&
      ProductsUtility.getContentByKey(
        offerBundle.contents,
        'PROMO_CODE_MESSAGE',
      ) === 'true'
    ) {
      sliceView.promoCodeInput = {
        promoCodeCheckoutMessage:
          translations.PRICING_PROMO_CODE_CHECKOUT_LABEL,
      };
    }

    if (sliceView.changeDuration?.addonToggle) {
      sliceView.changeDuration.addonToggle.isEnabled = ProductsUtility.stEquals(
        subscriptionType,
        'CLINICAL',
      );
      sliceView.changeDuration.addonToggle.onClick = onMedicationAccessChange;
    }

    // If there is an applicable experiment associated with this slice.
    if (slice.xsExperimentTextId && slice.sliceData) {
      sliceView = _.merge(sliceView, slice.sliceData);
    }

    plansDurationSlice = <PlansDurationSlice {...sliceView} />;
  }

  return (
    <>
      <div>{plansDurationSlice}</div>
    </>
  );
};
